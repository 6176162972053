import icons from './icons'
import theme from './theme'

export default {
  // product display information
  product: {
    name: 'Carbon UI',
    version: 'v1.2.0'
  },

  // icon libraries
  icons,

  // theme configs
  theme,
}
